import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Home} from './views';

export default function Application() {
  return (
    <BrowserRouter>
      <Home />
    </BrowserRouter>
  );
};
