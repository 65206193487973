import React from 'react';
import {Control} from '@features/Nft';

export function Home({}) {
  return (
    <>
      <Control image="/result.jpeg" />
    </>
  )
};
