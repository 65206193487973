import React, {useState, useEffect, useCallback, useRef} from 'react';
import clsx from 'clsx';

import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';

import useImage from '../hooks/useImage';

import styles from './Control.module.css';


interface ControlProps {
  className?: string;
  image?: string;
}

const minWidth = .15;

export default function Control({className, image: source}: ControlProps) {
  /*const {loading, result} = useImage({source, infoUrl: '/info.json'});*/
  const refImage = useRef<HTMLImageElement | null>(null);
  const refGrid = useRef<HTMLDivElement | null>(null);
  const refSelection = useRef<HTMLDivElement | null>(null);
  const [gridWidth, setGridWidth] = useState<number>();
  const [cellWidth, setCellWidth] = useState<number>();
  const [cellHeight, setCellHeight] = useState<number>();
  const [style, setStyle] = useState();
  const handleZoom = useCallback((e: unknown) => {
    console.log(e);
  }, []);  
  const handleClickGrid = useCallback((e: MouseEvent) => {
    if (refGrid.current && refImage.current) {
      const dx = refImage.current.offsetWidth / refImage.current.naturalWidth;
      const dy = refImage.current.offsetHeight / refImage.current.naturalHeight;
      const x = e.offsetX / dx;
      const y = e.offsetY / dy;
      const cx = Math.trunc(x / 40);
      const cy = Math.trunc(y / 44);
      if (refSelection.current) {
        const selX = cx * 40 * dx;
        const selY = cy * 44 * dy;
        console.log('x:',cx,'y:',cy, 'selX:', selX, 'selY', selY);
        refSelection.current.style.left = `${selX}px`;
        refSelection.current.style.top = `${selY}px`;
        refSelection.current.style.width = `${40 * dy}px`;
        refSelection.current.style.height = `${44 * dy}px`;
        const pw = dx * 1.5;
        const w = pw < minWidth ? minWidth : pw;
        //refSelection.current.style.borderWidth = `${w}px`;
        refSelection.current.style.opacity = '1';      
      }
      console.log(x, y, cx, cy);
    }
  }, [refGrid, refImage, refSelection]);
  const handleLoad = useCallback(() => {
    if (refImage.current && refGrid.current) {
      const dx = refImage.current.offsetWidth / refImage.current.naturalWidth;
      const dy = refImage.current.offsetHeight / refImage.current.naturalHeight;
      const pw = dx * 2;
      const w = pw < minWidth ? minWidth : pw;
      refGrid.current.style.backgroundSize = `${dx*40}px ${dy*44}px`;
      refGrid.current.style.backgroundImage = `linear-gradient(currentColor ${w}px, transparent ${w}px),linear-gradient(to right, currentColor ${w}px, transparent ${w}px)`;
      if (refImage.current.parentElement) refImage.current.parentElement.addEventListener('click', handleClickGrid);
    }
  }, [refImage, refGrid, handleClickGrid]);
  
  return (
    <section className={clsx(styles['container'], className)}>
      <header className={styles['header']}>
        
      </header>
      <main className={styles['control']}>
        <TransformWrapper limitToBounds onZoomStart={handleZoom}>
          <TransformComponent contentClass={styles['image-container']}>
            {/*result && result.source ? <img className={styles['image']} src={result.source} /> : null*/}
            <>
              <img className={styles['image']} src={source} onLoad={handleLoad} ref={refImage} />
              <div className={styles['grid']} ref={refGrid} />
              <div className={styles['selection']} ref={refSelection} />
            </>
          </TransformComponent>
        </TransformWrapper>
      </main>
      <footer className={styles['footer']}>

      </footer>
    </section>
  );
}