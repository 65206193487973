import React from "react";
import Application from './Application'
import './styles/index.css'

const application = <Application />

import('react-dom').then(ReactDOM => {
  const root = document.querySelector('#root')
  
  ReactDOM.render(application, root)
});
